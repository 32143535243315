/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import { QueryObjectFilterClause, DrillDownType, SqlaFormData } from './types';
import { ensureIsArray } from '../utils';
import { getXAxisColumn, isXAxisSet } from './getXAxis';
import { SetDataMaskHook } from '../chart';

export const chartIdSetDataMaskMap: Record<string, SetDataMaskHook> = {};
export const chartIdPrevPropsMap: Record<string, any> = {};

export default class DrillDown {
  static fromHierarchy(hierarchy: string[]): DrillDownType {
    // eslint-disable-next-line no-param-reassign
    hierarchy = ensureIsArray(hierarchy);
    return {
      hierarchy,
      currentIdx: hierarchy.length > 0 ? 0 : -1,
      filters: [],
    };
  }

  static fromFormData(formData: SqlaFormData): DrillDownType {
    let hierarchy = [
      ...(isXAxisSet(formData) ? ensureIsArray(getXAxisColumn(formData)) : []),
      ...ensureIsArray(formData.groupby),
    ];
    hierarchy = [...new Set(hierarchy)];
    return this.fromHierarchy(<string[]>hierarchy);
  }

  static drillDown(value: DrillDownType, selectValue: string): DrillDownType {
    const idx = value.currentIdx;
    const len = value.hierarchy.length;

    if (idx + 1 >= len) {
      return {
        hierarchy: value.hierarchy,
        currentIdx: 0,
        filters: [],
      };
    }
    return {
      hierarchy: value.hierarchy,
      currentIdx: idx + 1,
      filters: value.filters.concat({
        col: value.hierarchy[idx],
        op: 'IN',
        val: [selectValue],
      }),
    };
  }

  static rollUp(value: DrillDownType): DrillDownType {
    const idx = value.currentIdx;
    const len = value.hierarchy.length;
    return {
      hierarchy: value.hierarchy,
      currentIdx: idx - 1 < 0 ? len - 1 : idx - 1,
      filters: value.filters.slice(0, -1),
    };
  }

  static reset(value: DrillDownType): DrillDownType {
    return {
      hierarchy: value.hierarchy,
      currentIdx: 0,
      filters: value.filters.slice(0, -1 * value.currentIdx),
    };
  }

  static setIndex(value: DrillDownType, idx: number) {
    return {
      hierarchy: value.hierarchy,
      currentIdx: idx,
      filters: value.filters.slice(0, idx),
    };
  }

  static getColumn(
    value: DrillDownType | undefined | null,
    hierarchy: string[],
  ): string {
    if (value) {
      return value.hierarchy[value.currentIdx];
    }
    const val = DrillDown.fromHierarchy(hierarchy);
    return val.hierarchy[val.currentIdx];
  }

  static getFilters(
    value: DrillDownType | undefined | null,
    hierarchy: string[],
  ): QueryObjectFilterClause[] {
    if (value) {
      return value.filters;
    }
    const val = DrillDown.fromHierarchy(hierarchy);
    return val.filters;
  }

  static checkLastIdx(value: DrillDownType): boolean {
    return value.currentIdx + 1 >= value.hierarchy.length;
  }
}
