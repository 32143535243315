import { Select } from 'src/components';
import React, { useState } from 'react';
import { ActionEnum, AdhocMetric, IActionOption } from '@superset-ui/core';
import { RawValue } from '../Select/types';

interface PercentValue {
  firstArgCustom: AdhocMetric | null;
  secondArgCustom: AdhocMetric | null;
  action: IActionOption | null;
}

interface PercentOptionSelectorParams {
  adhocMetric: AdhocMetric;
  options: AdhocMetric[];
  value: PercentValue;
  onChange: (args: PercentValue) => void;
}

interface PercentOption {
  adhocMetric: AdhocMetric;
  value: string;
  label: string;
}

const actionOptions: IActionOption[] = [
  {
    value: ActionEnum.SUBTRACTION,
    label: 'Разность (—)',
    firstArgLabel: 'Выберите меру уменьшаемого',
    secondArgLabel: 'Выберите меры вычитаемого',
  },
  {
    value: ActionEnum.PERCENT,
    label: 'Процент (%)',
    firstArgLabel: 'Выберите меру процентной ставки',
    secondArgLabel: 'Выберите меру основы процента',
  },
];

export default function PercentOptionSelector({
  adhocMetric,
  options,
  onChange,
  value,
}: PercentOptionSelectorParams) {
  const [firstArgCustom, setPrincipal] = useState<AdhocMetric | null>(null);
  const [secondArgCustom, setPercentageRate] = useState<AdhocMetric | null>(
    null,
  );
  const [selectedAction, setSelectedOption] = useState<IActionOption | null>(
    value?.action ?? null,
  );

  const toOption = (adhocMetric: AdhocMetric | null) =>
    adhocMetric
      ? {
          value: adhocMetric.optionName as string,
          label: adhocMetric.label,
          adhocMetric,
        }
      : undefined;

  const optionItems = options
    .map(toOption)
    .filter(f => f && f.value !== adhocMetric.optionName) as PercentOption[];

  const onSelectPrincipal = (_: RawValue, option: PercentOption) => {
    setPrincipal(option.adhocMetric);
    onChange({
      firstArgCustom: option.adhocMetric,
      secondArgCustom,
      action: selectedAction,
    });
  };

  const onSelectPercentageRate = (_: RawValue, option: PercentOption) => {
    setPercentageRate(option.adhocMetric);
    onChange({
      firstArgCustom,
      secondArgCustom: option.adhocMetric,
      action: selectedAction,
    });
  };

  return (
    <>
      <Select
        options={optionItems}
        placeholder={selectedAction?.firstArgLabel}
        disabled={!selectedAction}
        value={toOption(value.secondArgCustom)?.value}
        onSelect={onSelectPercentageRate}
      />
      <Select
        placeholder="Выберите действие"
        options={actionOptions}
        value={selectedAction?.value}
        onSelect={(_: RawValue, option: IActionOption) =>
          setSelectedOption(option)
        }
        css={{ margin: '10px 0' }}
      />
      <Select
        placeholder={selectedAction?.secondArgLabel}
        options={optionItems}
        disabled={!selectedAction}
        value={toOption(value.firstArgCustom)?.value}
        onSelect={onSelectPrincipal}
      />
    </>
  );
}
