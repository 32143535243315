import { SQLEditor } from 'src/components/AsyncAceEditor';
import { AdhocMetric } from '@superset-ui/core';

import React, { useState, useEffect, useRef, memo, useCallback } from 'react';

interface CustomSQLEditorProps {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  height: number;
}
const CustomSQLEditor: React.FC<CustomSQLEditorProps> = memo(
  ({ value, placeholder, onChange, height }) => {
    const aceEditorRef = useRef<any>(null);
    useEffect(() => {
      if (aceEditorRef.current) {
        aceEditorRef.current.editor.resize();
      }
    }, []);

    return (
      <SQLEditor
        width="100%"
        height={`${height - 190}px`}
        className="filter-sql-editor"
        ref={aceEditorRef}
        showGutter={false}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        enableLiveAutocompletion
        wrapEnabled
      />
    );
  },
);

interface PercentValue {
  monthInput: string;
  quarterInput: string;
  yearInput: string;
}
interface dateOptionSelectorParams {
  adhocMetric: any;
  options: AdhocMetric[];
  value: PercentValue;
  onChange: (args: PercentValue) => void;
  height: number;
}

export default function CustomDates({
  adhocMetric,
  options,
  onChange,
  value,
  height,
}: dateOptionSelectorParams): JSX.Element {
  const [monthInput, setMonthInput] = useState(adhocMetric?.monthInput ?? '');
  const [quarterInput, setQuarterInput] = useState(
    adhocMetric?.quarterInput ?? '',
  );
  const [yearInput, setYearInput] = useState(adhocMetric?.yearInput ?? '');

  const handleMonthInput = useCallback(
    (monthText: string) => {
      setMonthInput(monthText);
    },
    [setMonthInput],
  );

  const handleQuarterInput = useCallback(
    (quarterText: string) => {
      setQuarterInput(quarterText);
    },
    [setQuarterInput],
  );

  const handleYearInput = useCallback(
    (yearText: string) => {
      setYearInput(yearText);
    },
    [setYearInput],
  );

  useEffect(() => {
    onChange({
      monthInput,
      quarterInput,
      yearInput,
    });
  }, [monthInput, onChange, quarterInput, yearInput]);

  return (
    <>
      <div>
        <span>Месяц</span>
        <CustomSQLEditor
          height={height}
          value={monthInput}
          placeholder="запрос для месяца"
          onChange={handleMonthInput}
        />
      </div>
      <div css={(theme: any) => ({ marginTop: theme.gridUnit * 2 })}>
        <span>Квартал</span>
        <CustomSQLEditor
          height={height}
          value={quarterInput}
          placeholder="запрос для квартала"
          onChange={handleQuarterInput}
        />
      </div>
      <div css={(theme: any) => ({ marginTop: theme.gridUnit * 2 })}>
        <span>Год</span>
        <CustomSQLEditor
          height={height}
          value={yearInput}
          placeholder="запрос для года"
          onChange={handleYearInput}
        />
      </div>
    </>
  );
}
