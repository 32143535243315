/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

/* eslint-disable camelcase */
import { QueryFormData } from './types/QueryFormData';
import { QueryObjectFilterClause } from './types/Query';
import { isSimpleAdhocFilter, isDateFreeFormAdhocFilter } from './types/Filter';
import convertFilter from './convertFilter';

function sanitizeClause(clause: string): string {
  let sanitizedClause = clause;
  if (clause.includes('--')) {
    sanitizedClause = `${clause}\n`;
  }
  return `(${sanitizedClause})`;
}

const diff_months_count = (startDate: string, endDate: string) => {
  let months;
  const d1 = new Date(startDate);
  const d2 = new Date(endDate);
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

/** Logic formerly in viz.py's process_query_filters */
export default function processFilters(
  formData: Partial<QueryFormData>,
): Partial<QueryFormData> {
  // Split adhoc_filters into four fields according to
  // (1) clause (WHERE or HAVING)
  // (2) expressionType
  //     2.1 SIMPLE (subject + operator + comparator)
  //     2.2 SQL (freeform SQL expression))
  const {
    adhoc_filters,
    extras = {},
    filters = [],
    where,
    extra_form_data,
  } = formData;
  const simpleWhere: QueryObjectFilterClause[] = filters;

  const freeformWhere: string[] = [];
  if (where) freeformWhere.push(where);
  const freeformHaving: string[] = [];

  (adhoc_filters || []).forEach(filter => {
    const { clause } = filter;
    if (isSimpleAdhocFilter(filter)) {
      const filterClause = convertFilter(filter);
      if (clause === 'WHERE') {
        simpleWhere.push(filterClause);
      }
    } else if (isDateFreeFormAdhocFilter(filter)) {
      const { monthSqlExpression, quarterSqlExpression, yearSqlExpression } =
        filter;

      let currentSQL = monthSqlExpression;

      if (extra_form_data?.time_range) {
        const date_range = extra_form_data?.time_range.split(' : ');

        const difMonth = diff_months_count(date_range[0], date_range[1]);

        if (difMonth < 3) {
          // console.log('----monthInput');
          currentSQL = monthSqlExpression;
        } else if (difMonth >= 3 && difMonth < 12) {
          // console.log('----quarterInput');
          currentSQL = quarterSqlExpression;
        } else if (difMonth >= 12) {
          // console.log('----yearInput');
          currentSQL = yearSqlExpression;
        }
      }

      if (clause === 'WHERE') {
        freeformWhere.push(currentSQL);
      } else {
        freeformHaving.push(currentSQL);
      }
    } else {
      const { sqlExpression } = filter;
      if (clause === 'WHERE') {
        freeformWhere.push(sqlExpression);
      } else {
        freeformHaving.push(sqlExpression);
      }
    }
  });

  // some filter-related fields need to go in `extras`
  extras.having = freeformHaving.map(sanitizeClause).join(' AND ');
  extras.where = freeformWhere.map(sanitizeClause).join(' AND ');

  return {
    filters: simpleWhere,
    extras,
  };
}
