import { cloneDeep } from 'lodash';
import { QueryFormMetric } from './types/QueryFormData';

const diff_months_count = (startDate: string, endDate: string) => {
  let months;
  const d1 = new Date(startDate);
  const d2 = new Date(endDate);
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

export default function metricsModify(
  metrics: any | undefined,
  overrides: { time_range?: string },
) {
  if (!metrics) return undefined;

  const res = cloneDeep(metrics)?.map((i: any) => {
    if (
      i?.expressionType === 'CUSTOM_DATES' ||
      i?.expressionType === 'CUSTOM_DATE'
    ) {
      const res = { ...i };

      let currentSQL = i?.yearInput;

      if (overrides?.time_range) {
        const date_range = overrides?.time_range?.split(' : ');

        const difMonth = diff_months_count(date_range[0], date_range[1]);

        if (difMonth < 3) {
          // console.log('----monthInput')
          currentSQL = i?.monthInput;
        } else if (difMonth >= 3 && difMonth < 12) {
          // console.log('----quarterInput')
          currentSQL = i?.quarterInput;
        } else if (difMonth >= 12) {
          // console.log('----yearInput')
          currentSQL = i?.yearInput;
        }
      }

      res.expressionType = 'SQL';
      res.sqlExpression = currentSQL;
      res.monthInput = null;
      res.quarterInput = null;
      res.yearInput = null;
      return res;
    }
    return i;
  });

  return res as QueryFormMetric[];
}
